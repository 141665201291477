.music__musicItem___23E6v {
}

.music__imageContainer___PcgmB {
}

.music__img___3Fhs- {
}

.music__textContainer___3pXW7 {
  top: 0;
  left: 0;
  padding: 50px;
  z-index: 2;
}

.music__h4___kT9Od {
}

.music__section___wbG2O {
  margin: 1em 0;
}

.music__quote___2exU9 {
  margin-left: 1em;
  margin-top: 0.5em;
}

@media (max-width: 615px) {
  .music__textContainer___3pXW7 {
    padding: 30px;
  }
}


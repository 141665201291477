.writing__content___1Izda {
  height: 600px;
}

.writing__content___1Izda:before {
  content: ' ';
  width: 100%;
  height: 150px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to(white));
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
}

.writing__post___2vmKt {
  margin-bottom: 50px;
  padding-bottom: 50px;
}

.writing__post___2vmKt img {
  width: 100%;
}

.writing__post___2vmKt pre {
  white-space: pre-wrap;
}

.writing__medium___2BepO {
  padding-bottom: 0px;
}

.writing__h2___30yX0 {
  margin-bottom: 0px;
  line-height: 1.3em;
  letter-spacing: -.018em;
}

.writing__h2___30yX0:first-of-type {
  margin-top: 0px;
}

.writing__link___eegRP {
  font-size: 14px;
  bottom: 0px;
  right: 0px;
}


.common__pink-text___3Iurf {
  color: rgb(255, 0, 116);
}
.common__gray-text___GGQ76 {
  color: rgba(0, 0, 0, 0.60);
}
.common__black-text___10kJg {
  color: #444;
}
.common__white-background____msTX {
  background-color: #FFF;
}
.common__black-background___24_fx {
  background-color: #444;
}
.common__relative___1DeOz {
  position: relative;
}
.common__absolute___2b-98 {
  position: absolute;
}
.common__inline___1IvjG {
  display: inline;
}
.common__full-width___1sWWI {
  width: 100%;
}
.common__overflow-hidden___1mdZq {
  overflow: hidden;
}
.common__media-item___2zKQK {
  margin: 70px 0;
  position: relative;
  min-height: 300px;
}
.common__media-item___2zKQK:first-of-type {
  margin-top: 30px;
}
.common__media-header___11r97 {
  margin: 0px;
  font-size: 40px;
  color: #FFF;
  background: rgba(255, 0, 116, 0.75);
  line-height: 1em;
}
.common__media-header___11r97 a {
  color: #FFF;
  font-weight: normal;
  border-bottom: none;
  padding: 10px;
  letter-spacing: -0.01em;
}
@media (max-width: 615px) {
  .common__media-header___11r97 {
    font-size: 30px;
  }
}
.common__serif___3j4yj {
  font-family: 'livory', 'Palatino', serif;
}
.common__buena-park___KyOcC {
  font-family: 'buena-park';
}
.common__baskerville___hCsXs {
  font-family: baskerville;
}
.common__normal___2qM-v {
  font-style: normal;
}
.common__italic___1JeSE {
  font-style: italic;
}
.common__text-center___3kXcY {
  text-align: center;
}
.common__no-decoration___3nL_p {
  text-decoration: none;
}


.footer__footer___G_tSf {
  font-size: 15px;
  margin: 40px 0px;
}

.footer__svg___1V2UX {
  display: inline-block;
  width: 25px;
  margin: 0px 5px;
}

.footer__svg___1V2UX * {
  fill: rgba(0, 0, 0, 0.60);
}

.footer__svg___1V2UX:hover * {
  fill: rgb(255, 0, 116);
}


.nav__nav___uVQNR {
  margin: 40px auto;
}

.nav__h2___1srsr {
  letter-spacing: -.02em;
  font-size: 1em;
}

.nav__link___1Uywn {
}

.nav__link___1Uywn:hover {
  color: rgb(255, 0, 116);
}

/*
 * NOTE(dbow): Composed .normal classname does not override .link font-style
 * sufficiently so I set it directly here. Composition is a _little_
 * unpredictable sometimes...
 */

.nav__active___X8ML8 {
  font-style: normal;
  font-weight: 700;
}

.nav__active___X8ML8:hover {
  color: rgba(0, 0, 0, 0.60);
}

.nav__amp___2R6rD {
}

@media (max-width: 615px) {
  .nav__nav___uVQNR {
    text-align: center;
    margin: 10px 0px;
  }
}


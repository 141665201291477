.work__work___33KaJ {
  padding: 30px;
}

.work__bg___Ws2ub {
  width: 110%;
  height: 110%;
  z-index: 0;
  left: -5%;
  top: -5%;
  -webkit-filter: blur(5px);
          filter: blur(5px);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}

.work__tale___2kcIx {
  background-image: url(/images/tale.png)
}

.work__livinglossures___2ND0f {
  background-image: url(/images/livinglossures.png);
}

.work__chartbeat___275ar {
  background-image: url(/images/chartbeat.png);
}

.work__soundquake___1D8R2 {
  background-image: url(/images/soundquake.png);
}

.work__kanyezone___XVnNU {
  background-image: url(/images/kanyezone.png);
}

.work__projectopen___rNhO3 {
  background-image: url(/images/projectopen.png);
}

.work__video___1_cE0 {
  left: 0px;
  top: 0px;
  height: 100%;
}

.work__h4___3IcLV {
}

.work__text___1LxN6 {
  padding: 20px;
  z-index: 2;
}

.work__p___hHm2I {
  padding: 10px;
}

@media (max-width: 615px) {
  .work__text___1LxN6 {
    padding: 0px;
  }
}

html, body {
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: #FFF;
}

body {
  font-size: 25px;
  margin: 0 auto;
  width: 600px;
  line-height: 1.3em;
  letter-spacing: -.005em;
}

h1 {
  font-weight: 400;
}

@media (max-width: 615px) {
  body {
    width: 90%;
    min-width: 300px;
    font-size: 20px;
  }
}

.instapoetry__poem___TWPia {
}

.instapoetry__img___2izM3 {
}

.instapoetry__caption___VzmQf {
  margin-top: 15px;
}

.instapoetry__drop___25c0T {
  float: left;
  font-size: 63px;
  margin-top: 23px;
  margin-right: 5px;
}

@media (max-width: 615px) {
  .instapoetry__drop___25c0T {
    font-size: 53px;
    margin-top: 19px;
  }
}

